'use client';

import { useDispatch, useSelector } from 'react-redux';
import Snow from "@/components/hero/snow/Snow";
import AuthModal from "@/components/auth/AuthModal";
import { Toaster } from "@/components/ui/toaster";
import { useEffect } from 'react';
import { closeLoginModal } from '@/store/reducers/loginModal';
import { useLenis } from "lenis/react";

interface FilterDetails {
  propertyTypes?: Set<string>;
  highlights?: Set<string>;
  amenities?: Set<string>;
  bedTypes?: Set<string>;
}

interface RootState {
  loginModal: {
    loginModal: boolean
  }
  filters: FilterDetails;
}


export default function LayoutContent({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const lenis = useLenis();

  const isLoginModalOpen = useSelector((state: RootState) => state.loginModal.loginModal);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js', { scope: '/' }).catch((error) => console.error('Service worker registration failed:', error));
    }
  }, []);

  function closeModal(open: boolean): void {
    if (!open) lenis?.start();

    if (!isLoginModalOpen) return;
    
    dispatch(closeLoginModal());
  }

  return (
    <>
      {/* <div className="top-0 left-0 z-50 fixed w-screen h-full pointer-events-none">
        <Snow />
      </div> */}

      {children}
      <Toaster />
      <AuthModal isOpen={isLoginModalOpen} onOpenChange={closeModal} activeTab="login" />
    </>
  );
}
